import { default as accountiPk3eaoYu7Meta } from "/opt/build/repo/pages/account.vue?macro=true";
import { default as _91_46_46_46slug_93o2ynx6pfGIMeta } from "/opt/build/repo/pages/cancel/[...slug].vue?macro=true";
import { default as custom_45intenttHCJ75dRJ1Meta } from "/opt/build/repo/pages/custom-intent.vue?macro=true";
import { default as customBmgzpzMWC9Meta } from "/opt/build/repo/pages/custom.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_930OKGmsVVyaMeta } from "/opt/build/repo/pages/success/[...slug].vue?macro=true";
import { default as thank_45youLjYQltUuVZMeta } from "/opt/build/repo/pages/thank-you.vue?macro=true";
import { default as update_45payment_45successrOQyAEVuirMeta } from "/opt/build/repo/pages/update-payment-success.vue?macro=true";
import { default as update_45paymentCXQkwP2ibFMeta } from "/opt/build/repo/pages/update-payment.vue?macro=true";
export default [
  {
    name: accountiPk3eaoYu7Meta?.name ?? "account",
    path: accountiPk3eaoYu7Meta?.path ?? "/account",
    meta: accountiPk3eaoYu7Meta || {},
    alias: accountiPk3eaoYu7Meta?.alias || [],
    redirect: accountiPk3eaoYu7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93o2ynx6pfGIMeta?.name ?? "cancel-slug",
    path: _91_46_46_46slug_93o2ynx6pfGIMeta?.path ?? "/cancel/:slug(.*)*",
    meta: _91_46_46_46slug_93o2ynx6pfGIMeta || {},
    alias: _91_46_46_46slug_93o2ynx6pfGIMeta?.alias || [],
    redirect: _91_46_46_46slug_93o2ynx6pfGIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/cancel/[...slug].vue").then(m => m.default || m)
  },
  {
    name: custom_45intenttHCJ75dRJ1Meta?.name ?? "custom-intent",
    path: custom_45intenttHCJ75dRJ1Meta?.path ?? "/custom-intent",
    meta: custom_45intenttHCJ75dRJ1Meta || {},
    alias: custom_45intenttHCJ75dRJ1Meta?.alias || [],
    redirect: custom_45intenttHCJ75dRJ1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/custom-intent.vue").then(m => m.default || m)
  },
  {
    name: customBmgzpzMWC9Meta?.name ?? "custom",
    path: customBmgzpzMWC9Meta?.path ?? "/custom",
    meta: customBmgzpzMWC9Meta || {},
    alias: customBmgzpzMWC9Meta?.alias || [],
    redirect: customBmgzpzMWC9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/custom.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_930OKGmsVVyaMeta?.name ?? "success-slug",
    path: _91_46_46_46slug_930OKGmsVVyaMeta?.path ?? "/success/:slug(.*)*",
    meta: _91_46_46_46slug_930OKGmsVVyaMeta || {},
    alias: _91_46_46_46slug_930OKGmsVVyaMeta?.alias || [],
    redirect: _91_46_46_46slug_930OKGmsVVyaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/success/[...slug].vue").then(m => m.default || m)
  },
  {
    name: thank_45youLjYQltUuVZMeta?.name ?? "thank-you",
    path: thank_45youLjYQltUuVZMeta?.path ?? "/thank-you",
    meta: thank_45youLjYQltUuVZMeta || {},
    alias: thank_45youLjYQltUuVZMeta?.alias || [],
    redirect: thank_45youLjYQltUuVZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: update_45payment_45successrOQyAEVuirMeta?.name ?? "update-payment-success",
    path: update_45payment_45successrOQyAEVuirMeta?.path ?? "/update-payment-success",
    meta: update_45payment_45successrOQyAEVuirMeta || {},
    alias: update_45payment_45successrOQyAEVuirMeta?.alias || [],
    redirect: update_45payment_45successrOQyAEVuirMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/update-payment-success.vue").then(m => m.default || m)
  },
  {
    name: update_45paymentCXQkwP2ibFMeta?.name ?? "update-payment",
    path: update_45paymentCXQkwP2ibFMeta?.path ?? "/update-payment",
    meta: update_45paymentCXQkwP2ibFMeta || {},
    alias: update_45paymentCXQkwP2ibFMeta?.alias || [],
    redirect: update_45paymentCXQkwP2ibFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/update-payment.vue").then(m => m.default || m)
  }
]