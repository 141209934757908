export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"},{"name":"description","content":"Our personal portable air purifiers & home air purifiers with Dual HEPA carbon filters help in removing allergens, viruses, germs, dust, dander, smoke & pollution from the air. Keep your air clean, safe and fresh, at home or while traveling."},{"name":"apple-mobile-web-app-title","content":"Wynd - Store"},{"name":"application-name","content":"Wynd - Store"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"msapplication-config","content":"/favicon/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicon/favicon-192x192.png"},{"rel":"manifest","href":"/favicon/manifest.json"},{"rel":"mask-icon","href":"/favicon/safari-pinned-tab.svg","color":"#5bbad5"},{"rel":"shortcut icon","href":"/favicon/favicon.ico"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":true},{"rel":"preconnect","href":"https://unpkg.com","crossorigin":true},{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap"},{"rel":"stylesheet","href":"https://unpkg.com/primevue/resources/themes/tailwind-light/theme.css"},{"rel":"stylesheet","href":"https://unpkg.com/primevue/resources/primevue.min.css"},{"rel":"stylesheet","href":"https://unpkg.com/primeicons/primeicons.css"}],"style":[],"script":[],"noscript":[],"title":"Store – Wynd Sentry | Wynd Technologies, Inc."}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null